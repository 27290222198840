import React, { useEffect } from 'react'
import { createContext, useContext } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import AuthService from './AuthService'

const AuthContext = createContext(null)

export const AuthProvider = ({ children }) => {
  const getCurrentUser = () => {
    const rut = localStorage.getItem('RUT')
    const role = localStorage.getItem('ROLE')
    if (rut === null) {
      return null
    }
    return { rut, role }
  }

  const login = async (rut, password) => {
    try {
      const user = await AuthService.login({ rut, password })
      saveAccount(user.rut, user.role)
    } catch (error) {
      throw error
    }
  }

  const saveAccount = (rut, role) => {
    localStorage.setItem('RUT', rut)
    localStorage.setItem('ROLE', role)
  }

  const logout = async () => {
    localStorage.clear()
    try {
      await AuthService.logout()
    } catch (error) {
      throw error
    }
  }

  return (
    <AuthContext.Provider value={{ getCurrentUser, login, logout, saveAccount }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}

export const RequireAuth = ({ children }) => {
  const location = useLocation()
  const auth = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    AuthService.check().catch(() => {
      localStorage.clear()
      navigate('/login')
    })
  })

  if (!auth.getCurrentUser()) {
    return <Navigate to="/login" state={{ path: location.pathname }} />
  }

  return children
}
