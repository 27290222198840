import React from 'react'
import _ from 'lodash'

export const transformError = (error) => {
  if (error?.status === 400 && error?.data?.hasOwnProperty('messages')) {
    return error?.data?.messages.map((input, key) => {
      return (
        <div key={key}>
          <strong>{_.capitalize(input.property)}:</strong>
          <ul>
            {Object.entries(input.constraints).map((entity, key) => {
              return <li key={key}>{entity[1]}</li>
            })}
          </ul>
        </div>
      )
    })
  } else {
    return error?.data?.message ?? error?.statusText
  }
}
