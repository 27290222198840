import axios from 'axios'
import { ENV } from 'src/config'
import { transformError } from '../util/ErrorManager'

axios.defaults.withCredentials = true

const unauthorizedCode = [401, 403]

const service = axios.create({
  baseURL: ENV.API_ENDPOINT_URL,
  timeout: 60000,
})

// const createObjectIds = (data, name) => {
//   data[`${name}Ids`] = data[name]?.reduce((p, c) => {
//     p[c.id] = c
//     return p
//   }, {})
//   return data
// }

service.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    // TODO: Agregar Logica de notificacions
    if (unauthorizedCode.includes(error?.response?.status)) {
      localStorage.removeItem('RUT')
      localStorage.removeItem('ROLE')
      // redireccionar a login
    }
    let type = 'critical'
    if (!!error?.response?.data?.hasOwnProperty('message')) {
      type = 'normal'
    }
    if (!!error?.response?.data?.hasOwnProperty('messages')) {
      type = 'form'
    }

    return Promise.reject({
      html: transformError(error?.response),
      error,
      type,
      normal: error?.response?.data,
      form: error?.response?.data?.messages,
    })
  },
)

export default service
