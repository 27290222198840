import fetch from './FetchInterceptor'

const AuthService = {}

AuthService.login = function (data) {
  return fetch({
    url: '/auth/login',
    method: 'post',
    data: data,
  })
}

AuthService.checkRut = function (data) {
  return fetch({
    url: '/auth/check-rut',
    method: 'post',
    data: data,
  })
}

AuthService.checkToken = function (data) {
  return fetch({
    url: '/auth/check-token',
    method: 'post',
    data: data,
  })
}

AuthService.changePassword = function (data) {
  return fetch({
    url: '/auth/change-password',
    method: 'put',
    data: data,
  })
}

AuthService.recoverPassword = function (data) {
  return fetch({
    url: '/auth/recover-password',
    method: 'post',
    data: data,
  })
}

AuthService.logout = function () {
  return fetch({
    url: '/auth/logout',
    method: 'post',
  })
}

AuthService.check = function () {
  return fetch({
    url: '/auth/check',
    method: 'get',
  })
}

export default AuthService
